import { APICompsSchema } from "@/apis/api-config";

import { TaskSortingSateType } from "@/types/app-task-types";
import { atom } from "jotai";

// for auth
export const currentUserAtom = atom<APICompsSchema["AppUserObject"] | null>(null);
export const selectedWorkspaceAtom = atom<APICompsSchema["WorkspaceObject"] | null>(null);

// for communication
export const meetingsAtom = atom<APICompsSchema["MeetingObject"][]>([]);

// for organization
export const activeStaffsAtom = atom<APICompsSchema["WorkspaceUserObject"][]>([]);
export const activeStaffMapAtom = atom<Map<string, APICompsSchema["WorkspaceUserObject"]>>(new Map());
export const activeStaffLastNameCountMapAtom = atom<Map<string, number>>(new Map());
export const archivedStaffsAtom = atom<APICompsSchema["WorkspaceUserObject"][]>([]);
export const groupsAtom = atom<APICompsSchema["GroupObject"][]>([]);
export const groupMapAtom = atom<Map<string, APICompsSchema["GroupObject"]>>(new Map());
export const topdownGroupsAtom = atom<APICompsSchema["TopdownGroupObject"][]>([]);
export const alignedGroupsAtom = atom<APICompsSchema["GroupObject"][]>([]);
export const projectsAtom = atom<APICompsSchema["ProjectObject"][]>([]);

// for task
export const mgmtTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);
export const groupTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);
export const allGroupTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);
export const projectTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);
export const allProjectTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);
export const myPrivateTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);
export const myMgmtTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);
export const myGroupTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);
export const myProjectTasksAtom = atom<APICompsSchema["TaskObject"][]>([]);

// for api sort
// 形式: `sortBy=[attribute_name]-[asc/desc]` （例: `createdAt-desc` 作成日時の降順 ）
// https://docs.google.com/spreadsheets/d/1_EM992adNXZWGyxRcDkEgs5KumhZq2TrEh23dZGRcdc/edit?usp=sharing
export const taskSortingStateAtom = atom<TaskSortingSateType>({
  me: undefined,
  group: undefined,
  management: undefined,
  public: undefined,
  project: undefined,
});

export const staffSortingStateAtom = atom<string | undefined>(undefined);
export const projectSortingStateAtom = atom<string | undefined>(undefined);
export const meetingSortingAtom = atom<string | undefined>(undefined);
export const meetingRoomNamesAtom = atom<string[]>([]);
