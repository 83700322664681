import { jaJP } from "@mui/material/locale";
import { ThemeOptions, createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

import { appColor } from "./app-colors";

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: appColor.AuthApp.primary,
    },
    secondary: {
      main: appColor.AuthApp.secondary,
    },
    error: {
      main: appColor.AuthApp.error,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  // MuiDataGrid
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // backgroundColor: "red",
        },
      },
    },
  },
};

export const muiTheme = createTheme(themeOptions, jaJP);
