export const SNACK_BAR_AUTO_HIDE_DURATION_M_SEC = 6000;

export const APP_EXPIRED_DATE_COUNT = 3;

export const PAPER_ELEVATION_APP_FOR_EDIT_CELL = 1;

export const EXTRA_ROWS_NUM = 30;

export const GOOGLE_AUTH_CLIENT_ID = process.env.GOOGLE_AUTH_CLIENT_ID || "google_auth_client_id";

export const IME_KEY_CODE = 229;

export const ELEVATION_APP = 3;

export const INITIAL_ROWS_PER_PAGE = 25;

export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 200];

export const MAX_TEXT_LENGTH = 24;
