import React, { useEffect } from "react";

import { LicenseInfo } from "@mui/x-data-grid-pro";

type Props = {
  children: React.ReactNode;
};

const MuiLicenseProvider: React.FC<Props> = (props) => {
  useEffect(() => {
    LicenseInfo.setLicenseKey(process.env.MUI_DATAGRID_LICENSE_KEY || "");
  }, []);

  return <>{props.children}</>;
};

export default React.memo(MuiLicenseProvider);
