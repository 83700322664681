import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class SecondaryItemTplApi extends BaseApi {
  async getSecondaryItemTplList(
    queryParameters: APIPaths["/admin-dash/secondary-item-tpl/list"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/admin-dash/secondary-item-tpl/list"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/admin-dash/secondary-item-tpl/list", { params: queryParameters })).data;
  }

  async postSecondaryItemTplNew(
    requestBody: APIPaths["/admin-dash/secondary-item-tpl/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/secondary-item-tpl/new"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post("/admin-dash/secondary-item-tpl/new", requestBody)).data;
  }

  async getSecondaryItemTplById(
    pathParameters: APIPaths["/admin-dash/secondary-item-tpl/{id}"]["get"]["parameters"]["path"],
  ): Promise<
    APIPaths["/admin-dash/secondary-item-tpl/{id}"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/admin-dash/secondary-item-tpl/${pathParameters.id}`)).data;
  }

  async patchSecondaryItemTplById(
    pathParameters: APIPaths["/admin-dash/secondary-item-tpl/{id}"]["patch"]["parameters"]["path"],
    requestBody: APIPaths["/admin-dash/secondary-item-tpl/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/secondary-item-tpl/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.patch(`/admin-dash/secondary-item-tpl/${pathParameters.id}`, requestBody)).data;
  }

  async deleteSecondaryItemTplById(
    pathParameters: APIPaths["/admin-dash/secondary-item-tpl/{id}"]["delete"]["parameters"]["path"],
  ): Promise<
    APIPaths["/admin-dash/secondary-item-tpl/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.delete(`/admin-dash/secondary-item-tpl/${pathParameters.id}`)).data;
  }
}
