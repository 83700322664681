import { useMediaQuery, useTheme } from "@mui/material";

export type MuiMediaQuery = "xs" | "sm" | "md" | "lg" | "xl";

export const useCustomMediaQuery = () => {
  const theme = useTheme();
  const breakpoints = theme.breakpoints;
  const isMobile = !useMediaQuery(breakpoints.up("sm"));
  const isTablet = !useMediaQuery(breakpoints.up("md"));
  const isDesktop = !useMediaQuery(breakpoints.up("lg"));
  const isMonitor = !useMediaQuery(breakpoints.up("xl"));
  return { breakpoints: breakpoints, isMobile, isTablet, isDesktop, isMonitor };
};

export const useBetweenMediaQuery = (min: MuiMediaQuery, max: MuiMediaQuery) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between(min, max));
};
