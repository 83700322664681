import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class GroupApi extends BaseApi {
  async getGroupList(
    params: APIPaths["/admin-dash/group/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/group/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/group/list", { params })).data;
  }

  async getTopdownGroupListAll(
    params: APIPaths["/admin-dash/group/topdown/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/group/topdown/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/group/topdown/list-all", { params })).data;
  }

  async getGroupIdNameListAll(
    params: APIPaths["/admin-dash/group/id-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/group/id-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/group/id-name/list-all", { params })).data;
  }

  async postGroupNew(
    requestBody: APIPaths["/admin-dash/group/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/group/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/group/new", requestBody)).data;
  }

  async getGroupById(
    id: string,
  ): Promise<APIPaths["/admin-dash/group/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/group/${id}`)).data;
  }

  async patchGroupById(
    id: string,
    requestBody: APIPaths["/admin-dash/group/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/group/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/group/${id}`, requestBody)).data;
  }

  async deleteGroupById(
    id: string,
  ): Promise<APIPaths["/admin-dash/group/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/group/${id}`)).data;
  }
}
