import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class MeetingApi extends BaseApi {
  async getMeetingList(
    params: APIPaths["/admin-dash/meeting/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/meeting/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/meeting/list", { params })).data;
  }

  async postMeetingNew(
    requestBody: APIPaths["/admin-dash/meeting/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/meeting/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/meeting/new", requestBody)).data;
  }

  async getMeetingById(
    id: string,
  ): Promise<APIPaths["/admin-dash/meeting/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/meeting/${id}`)).data;
  }

  async patchMeetingById(
    id: string,
    requestBody: APIPaths["/admin-dash/meeting/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/meeting/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/meeting/${id}`, requestBody)).data;
  }

  async deleteMeetingById(
    id: string,
  ): Promise<APIPaths["/admin-dash/meeting/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/meeting/${id}`)).data;
  }

  async putMeetingPositionById(
    id: string,
    requestBody: APIPaths["/admin-dash/meeting/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/meeting/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/meeting/${id}/position`, requestBody)).data;
  }

  async getMeetingRoomNameList(
    parameters: APIPaths["/admin-dash/meeting/room-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/admin-dash/meeting/room-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/admin-dash/meeting/room-name/list-all", { params: parameters })).data;
  }
  async putMeetingPeriodicById(
    id: string,
    requestBody: APIPaths["/admin-dash/meeting/{id}/periodic"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/meeting/{id}/periodic"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/meeting/${id}/periodic`, requestBody)).data;
  }
}
