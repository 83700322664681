import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class BusinessCategoryApi extends BaseApi {
  async getBusinessCategoryList(
    params: APIPaths["/admin-dash/business-category/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/business-category/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/business-category/list", { params })).data;
  }

  async getBusinessCategoryIdNameListAll(
    params: APIPaths["/admin-dash/business-category/id-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/admin-dash/business-category/id-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/admin-dash/business-category/id-name/list-all", { params })).data;
  }

  async postBusinessCategoryNew(
    requestBody: APIPaths["/admin-dash/business-category/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/business-category/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/admin-dash/business-category/new`, requestBody)).data;
  }

  async getBusinessCategoryById(
    id: string,
  ): Promise<APIPaths["/admin-dash/business-category/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/business-category/${id}`)).data;
  }

  async patchBusinessCategoryById(
    id: string,
    requestBody: APIPaths["/admin-dash/business-category/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/business-category/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.patch(`/admin-dash/business-category/${id}`, requestBody)).data;
  }

  async deleteBusinessCategoryById(
    id: string,
  ): Promise<APIPaths["/admin-dash/company/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/company/${id}`)).data;
  }
}
