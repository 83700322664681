import { CSSProperties } from "react";

import { appColor } from "@/styles/app-colors";

import localfont from "@next/font/local";

export const kintoSans = localfont({
  src: [
    {
      path: "KintoSans/KintoSans-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "KintoSans/KintoSans-Medium.woff2",
      weight: "500",
      style: "normal",
    },
    {
      path: "KintoSans/KintoSans-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "KintoSans/KintoSans-Thin.woff2",
      weight: "300",
      style: "normal",
    },
  ],
});

export const appTypo = {
  font: {
    kintoSans: kintoSans.style.fontFamily,
  },
} as const;

export const highlightActiveStyle: CSSProperties = {
  color: appColor.Accent.red,
  fontWeight: "bold",
};
