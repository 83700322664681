import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class WorkspaceApi extends BaseApi {
  async getWorkspaceList(
    params: APIPaths["/admin-dash/workspace/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/workspace/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/workspace/list", { params })).data;
  }

  async getWorkspaceIdNameListAll(
    params: APIPaths["/admin-dash/workspace/id-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/admin-dash/workspace/id-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/admin-dash/workspace/id-name/list-all", { params })).data;
  }

  async postWorkspaceNew(
    requestBody: APIPaths["/admin-dash/workspace/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/workspace/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/workspace/new", requestBody)).data;
  }

  async getWorkspaceById(
    id: string,
  ): Promise<APIPaths["/admin-dash/workspace/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/workspace/${id}`)).data;
  }

  async patchWorkspaceById(
    id: string,
    requestBody: APIPaths["/admin-dash/workspace/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/workspace/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/workspace/${id}`, requestBody)).data;
  }

  async deleteWorkspaceById(
    id: string,
  ): Promise<APIPaths["/admin-dash/workspace/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/workspace/${id}`)).data;
  }

  async putWorkspaceById(
    id: string,
    requestBody: APIPaths["/admin-dash/workspace/{id}/status"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/workspace/{id}/status"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/workspace/${id}/status`, requestBody)).data;
  }

  async putWorkspaceOfficialPositionById(
    id: string,
    requestBody: APIPaths["/admin-dash/workspace/{id}/official-position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/workspace/{id}/official-position"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/workspace/${id}/official-position`, requestBody)).data;
  }
}
