import { css } from "@emotion/react";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

export const globalStyled = css`
  html {
    font-family: "kinto-sans", "Avenir", "Helvetica Neue", "Helvetica", "Arial", "ヒラギノ角ゴ Pro W3",
      "Hiragino Kaku Gothic Pro", "メイリオ", sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    background-color: #ffffff;
    font-style: normal;
    font-weight: normal;
    min-height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  /*スクロールバー非表示（Chrome・Safari）*/
  body::-webkit-scrollbar {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: inherit;
    }
  }

  .firebase-emulator-warning {
    display: none;
  }
  *:focus {
    outline: none;
  }
  & .MuiDivider-root {
    border-color: #d9d9d9;
  }
  & .MuiListItemButton-root {
    &:hover {
      background: #f8f8fa;
      border-radius: 4px;
      & .MuiTypography-root {
        font-weight: 500;
      }
    }
  }
  & .MuiMenu-list {
    max-height: 540px;
  }
  & .MuiDialogContent-root {
    background: #fbfbfb;
  }
  & .MuiDataGrid-columnHeaders {
    border-bottom: 1px solid #d9d9d9 !important;
  }

  //  react-toastify-css
  .Toastify__toast-container {
    .Toastify__toast {
      min-height: 48px;
    }
    .Toastify__toast-body {
      font-family: ${appTypo.font.kintoSans};
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: ${appColor.Text.white};
    }
  }

  .Toastify__toast-theme--colored.Toastify__toast--default {
    background: ${appColor.Text.secondary};
  }
  .Toastify__toast-theme--colored.Toastify__toast--info {
    background: ${appColor.Accent.blue};
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: ${appColor.Accent.green};
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background: ${appColor.Accent.orange};
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background: ${appColor.Accent.red};
  }
  .Toastify__progress-bar {
    background: ${appColor.App.white};
  }
  .Toastify__close-button > svg {
    fill: ${appColor.App.white};
  }
`;
