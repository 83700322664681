import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class TaskApi extends BaseApi {
  async getTaskList(
    params: APIPaths["/admin-dash/task/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/task/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/task/list", { params })).data;
  }

  async postTaskNew(
    requestBody: APIPaths["/admin-dash/task/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/task/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/task/new", requestBody)).data;
  }

  async getTaskById(
    id: string,
  ): Promise<APIPaths["/admin-dash/task/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/task/${id}`)).data;
  }

  async patchTaskById(
    id: string,
    requestBody: APIPaths["/admin-dash/task/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/task/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/task/${id}`, requestBody)).data;
  }

  async deleteTaskById(
    id: string,
  ): Promise<APIPaths["/admin-dash/task/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/task/${id}`)).data;
  }

  async postTaskDuplicateById(
    id: string,
    requestBody: APIPaths["/admin-dash/task/{id}/duplicate"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/task/{id}/duplicate"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/admin-dash/task/${id}/duplicate`, requestBody)).data;
  }

  async putTaskPeriodicById(
    id: string,
    requestBody: APIPaths["/admin-dash/task/{id}/periodic"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/task/{id}/periodic"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/admin-dash/task/${id}/periodic`, requestBody)).data;
  }

  async deleteTaskPeriodicById(
    id: string,
  ): Promise<APIPaths["/admin-dash/task/{id}/periodic"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/task/${id}/periodic`)).data;
  }

  async putTaskPositionById(
    id: string,
    requestBody: APIPaths["/admin-dash/task/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/task/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/task/${id}/position`, requestBody)).data;
  }
}
