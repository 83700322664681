import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class UserApi extends BaseApi {
  async getUserList(
    params: APIPaths["/admin-dash/user/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/user/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/user/list", { params })).data;
  }

  async postUserNew(
    requestBody: APIPaths["/admin-dash/user/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/user/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/admin-dash/user/new`, requestBody)).data;
  }

  async getUserById(
    id: string,
  ): Promise<APIPaths["/admin-dash/user/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/user/${id}`)).data;
  }

  async deleteUserById(
    id: string,
  ): Promise<APIPaths["/admin-dash/user/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/user/${id}`)).data;
  }

  async postUserEmailById(
    id: string,
    requestBody: APIPaths["/admin-dash/user/{id}/email"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/user/{id}/email"]["post"]["responses"]["200"]["content"]["application/json"]["object"]
  > {
    return (await this.post(`/admin-dash/user/${id}/email`, requestBody)).data;
  }

  async deleteUserEmailById(
    id: string,
    requestBody: APIPaths["/admin-dash/user/{id}/email"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/user/{id}/email"]["delete"]["responses"]["200"]["content"]["application/json"]["object"]
  > {
    return (await this.delete(`/admin-dash/user/${id}/email`, { data: requestBody })).data;
  }

  async putUserPasswordById(
    id: string,
    requestBody: APIPaths["/admin-dash/user/{id}/password"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/user/{id}/password"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/admin-dash/user/{id}/password`, requestBody)).data;
  }
}
