import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class AuthApi extends BaseApi {
  async postRenewToken(): Promise<
    APIPaths["/admin-dash/auth/renew-token"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post("/admin-dash/auth/renew-token")).data;
  }

  async postLoginWithEmail(
    requestBody: APIPaths["/admin-dash/auth/login"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/app/auth/login-with-email"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/auth/login", requestBody)).data;
  }
}
