// local or stating or production
let appEnvSuffix = "F5DDA";
if (process.env.APP_ENV === "stating") {
  appEnvSuffix = "830F7";
} else if (process.env.APP_ENV === "production") {
  appEnvSuffix = "FD897";
}

export const LocalStorageKeys = {
  authAccessToken: {
    key: "083EDDBFC1062F1E5E30448C7492116C" + appEnvSuffix,
  },
  authRefreshToken: {
    key: "EDCB5A177AFF6E1B0D51538EE355F63F" + appEnvSuffix,
  },
  currentWorkspaceId: {
    key: "87C3DF116434042E4F4EE3DBC7D4EA50" + appEnvSuffix,
  },
  currentGroupId: {
    key: "97ED73C6FA80FF41ADC9ACF0F695E274" + appEnvSuffix,
  },
  currentProjectId: {
    key: "71AAB67904BD257186992BF9E51D25CE" + appEnvSuffix,
  },
  currentPrimaryItemType: {
    key: "3D14AD02258E97C2391356BFCFEA7012" + appEnvSuffix,
  },
  currentGroupSection: {
    key: "3E12344A15B4E1B45008233C874EF55E" + appEnvSuffix,
  },
  currentTaskStatus: {
    key: "EBAE02BD4DE63D742B6BDFE77CD07829" + appEnvSuffix,
  },
  currentTaskKind: {
    key: "F0BBC01B22C643B6734A77CC70FDED59" + appEnvSuffix,
  },
} as const;
