import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class InvoiceApi extends BaseApi {
  async getInvoiceList(
    parameters: APIPaths["/admin-dash/invoice/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/invoice/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/invoice/list", {
        params: parameters,
      })
    ).data;
  }

  async getInvoiceById(
    parameters: APIPaths["/admin-dash/invoice/{id}"]["get"]["parameters"]["path"],
  ): Promise<APIPaths["/admin-dash/invoice/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/invoice/${parameters.id}`)).data;
  }
}
