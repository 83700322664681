import { useRouter } from "next/router";
import { useCallback } from "react";

import { APIPaths } from "@/apis/api-config";
import { AdminApi } from "@/apis/connect/admin-api";
import { AuthApi } from "@/apis/connect/auth-api";
import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";
import { useCommonSetting } from "@/custom-hooks/use-common-setting";
import { DASHBOARD_PATH, LOGIN_PATH } from "@/constants/app-path";
import { authAdminAtom, authTokenInfoAtom, fbUserCredentialAtom, loadingAtom } from "@/global-states/common-atoms";
import { LocalStorageKeys } from "@/constants/local-storage-keys";

import { useAtom, useSetAtom } from "jotai";
import localforage from "localforage";
import { useSnackbar } from "notistack";
import { selectedWorkspaceAtom } from "@/global-states/api-atoms";
import { firebaseAuth } from "@/utils/firebase-utils";
import { signInWithCustomToken, signOut } from "@firebase/auth";

export const useAuth = () => {
  const router = useRouter();
  const { authApi, workspaceApi } = useApiConnector();
  const { enqueueSnackbar } = useSnackbar();
  const { resetAuth, catchApiError, checkRefreshToken } = useCommonSetting();
  const [, setLoading] = useAtom(loadingAtom);
  const [, setAuthAdmin] = useAtom(authAdminAtom);
  const [, setAuthTokenInfo] = useAtom(authTokenInfoAtom);
  const [selectedWorkspace, setSelectedWorkspace] = useAtom(selectedWorkspaceAtom);
  const setFbUserCredential = useSetAtom(fbUserCredentialAtom);

  const authSync = useCallback(async () => {
    setLoading(true);
    try {
      const refreshToken = await checkRefreshToken();
      if (!refreshToken) {
        await resetAuth();
        return false;
      }

      const authApi = new AuthApi(refreshToken);
      const renewTokenResp = await authApi.postRenewToken();
      setAuthTokenInfo(renewTokenResp);
      const adminApi = new AdminApi(renewTokenResp.access_token);
      const adminResp = await adminApi.getAdminMe();
      setAuthAdmin(adminResp.object);

      return true;
    } catch (err) {
      catchApiError(err);
      await resetAuth();
      return false;
    } finally {
      setLoading(false);
    }
  }, [authApi]);

  const fbAuthSync = async (fbCustomToken?: string) => {
    if (!fbCustomToken) {
      try {
        setFbUserCredential(undefined);
        await signOut(firebaseAuth);
      } catch (e) {
        console.warn("### failed signOut for firebase. ", e);
      }
    } else {
      try {
        const userCredential = await signInWithCustomToken(firebaseAuth, fbCustomToken);
        setFbUserCredential(userCredential);
      } catch (e) {
        console.error("### failed signInWithCustomToken for firebase. ", e);
      }
    }
  };

  const authLoginWithEmail = useCallback(
    async (requestBody: APIPaths["/admin-dash/auth/login"]["post"]["requestBody"]["content"]["application/json"]) => {
      setLoading(true);
      try {
        const resultLogin = await authApi.postLoginWithEmail(requestBody);
        setAuthTokenInfo(resultLogin);
        await localforage.setItem(LocalStorageKeys.authAccessToken.key, resultLogin.access_token);
        await localforage.setItem(LocalStorageKeys.authRefreshToken.key, resultLogin.refresh_token);
        const adminApi = new AdminApi(resultLogin.access_token);
        const adminResp = await adminApi.getAdminMe();
        setAuthAdmin(adminResp.object);
        enqueueSnackbar("ログインに成功しました!", {
          variant: "success",
        });
        return await router.push(DASHBOARD_PATH);
      } catch (err) {
        catchApiError(err);
        await resetAuth();
      } finally {
        setLoading(false);
      }
    },
    [authApi],
  );

  const authLogout = useCallback(async () => {
    setLoading(true);
    try {
      await resetAuth();
      enqueueSnackbar("ログアウトしました", {
        variant: "info",
      });
      return await router.push(LOGIN_PATH);
    } catch (err) {
      catchApiError(err);
    } finally {
      setLoading(false);
    }
  }, [authApi]);

  const syncSelectedWorkspace = useCallback(async () => {
    if (!selectedWorkspace) {
      return;
    }
    setLoading(true);
    try {
      const workspaceResp = await workspaceApi.getWorkspaceById(selectedWorkspace.id);
      setSelectedWorkspace(workspaceResp.object);
    } catch (err) {
      catchApiError(err);
    } finally {
      setLoading(false);
    }
  }, [workspaceApi, selectedWorkspace]);

  return {
    authSync,
    fbAuthSync,
    authLoginWithEmail,
    authLogout,
    syncSelectedWorkspace,
  };
};
