import React, { useEffect } from "react";

import { primaryItemTypeOptions } from "@/apis/api-options";
import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";
import { useCommonSetting } from "@/custom-hooks/use-common-setting";
import { LocalStorageKeys } from "@/constants/local-storage-keys";

type Props = {
  children: React.ReactNode;
};

const GlobalJotaiProvider: React.FC<Props> = (props) => {
  const {
    authAdmin,
    setWorkspaceOpts,
    setGroupOpts,
    setProjectOpts,
    currentPrimaryItemType,
    setCurrentPrimaryItemType,
    currentWorkspaceOpt,
  } = useCommonSetting();
  const { workspaceApi, groupApi } = useApiConnector();

  useEffect(() => {
    setTimeout(async () => {
      const primaryItemType = localStorage.getItem(LocalStorageKeys.currentPrimaryItemType.key);
      const primaryItemTypeOpt = primaryItemTypeOptions.find(({ id }) => primaryItemType === id) || null;
      setCurrentPrimaryItemType(primaryItemTypeOpt?.id || "management");
    }, 1);
  }, []);

  useEffect(() => {
    if (!authAdmin) {
      setWorkspaceOpts([]);
      return;
    }
    setTimeout(async () => {
      const resp = await workspaceApi.getWorkspaceIdNameListAll({
        with_code: "true",
        with_company_name: "false",
        with_task_count: "false",
      });
      setWorkspaceOpts(resp.objects.map((o) => ({ id: o.id, name: `${o.name}（${o.ex_data?.code}）` })));
    }, 1);
  }, [authAdmin]);

  useEffect(() => {
    if (!authAdmin) {
      setGroupOpts([]);
      setProjectOpts([]);
      return;
    }
    if (currentWorkspaceOpt) {
      if (currentPrimaryItemType === "group") {
        setTimeout(async () => {
          const resp = await groupApi.getGroupIdNameListAll({ with_task_count: "true" });
          setGroupOpts(resp.objects);
        }, 1);
      } else if (currentPrimaryItemType === "project") {
        setTimeout(async () => {
          const resp = await groupApi.getGroupIdNameListAll({ with_task_count: "true" });
          setProjectOpts(resp.objects);
        }, 1);
      } else {
        setGroupOpts([]);
        setProjectOpts([]);
      }
    } else {
      setGroupOpts([]);
      setProjectOpts([]);
    }
  }, [currentPrimaryItemType, currentWorkspaceOpt]);

  return <>{props.children}</>;
};

export default GlobalJotaiProvider;
