import { EmotionCache, Global } from "@emotion/react";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { SNACK_BAR_AUTO_HIDE_DURATION_M_SEC } from "@/constants/app-config";
import { globalStyled } from "@/styles/emotion-global";
import { muiTheme } from "@/styles/mui-theme";

import SnackbarCloseButton from "@/components/layout/SnackBarCloseButton";
import { AuthProvider } from "@/components/provider/AuthProvider";
import GlobalJotaiProvider from "@/components/provider/GlobalJotaiProvider";
import MuiLicenseProvider from "@/components/provider/MuiLicenseProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider as JotaiProvider } from "jotai";
import { SnackbarProvider } from "notistack";
import "react-pro-sidebar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";

const Layout = dynamic(async () => import("@/components/Layout"), { ssr: false });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 30000,
    },
  },
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps): JSX.Element {
  const { Component, pageProps } = props;

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <SnackbarProvider
          hideIconVariant
          autoHideDuration={SNACK_BAR_AUTO_HIDE_DURATION_M_SEC}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          maxSnack={3}
          preventDuplicate
          action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
        >
          <CssBaseline />
          <Global styles={globalStyled} />
          <JotaiProvider>
            <GlobalJotaiProvider>
              <MuiLicenseProvider>
                <AuthProvider>
                  <Layout noHeader={!!pageProps.noHeader}>
                    <Component {...pageProps} />
                  </Layout>
                </AuthProvider>
              </MuiLicenseProvider>
            </GlobalJotaiProvider>
          </JotaiProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
