export const appColor = {
  AuthApp: {
    primary: "#34387c",
    secondary: "#646464",
    accent: "#DE3E44",
    white: "#FFFFFF",
    error: "#DE3E44",
  },
  AuthAccent: {
    primary: "#AD002D",
    secondary: "#181B39",
    orange: "#EA930A",
    purple: "#624498",
    yellow: "#FFC700",
    red: "#EA0032",
    green: "#00A650",

    subBlue: "#1E88E5",
    subRed: "#FF7474",
    subGreen: "#00D4BB",
  },
  AuthText: {
    primary: "#00081A", // 主にメインテキストなどに使用
    secondary: "#646464", // サブテキストなどに使用
    white: "#FFFFFF",
    gray: "#8C949B",
    head: "#646464",
    brand: "#1A296F",
    subBrand: "#C2C9DE",
    sideNav: "#6a6a6a",
  },
  AuthBorder: {
    primary: "#F6F7F8", // Divider/Border/Strokeが主にアクティブなときに使用
    secondary: "#F7F7F7", // Divider/Border/Strokeが主に非アクティブなときに使用
    gray: "#DADCE0",
    third: "#F1F1F1",
    headerBell: "#D3DBE2",
  },
  AuthBackground: {
    primary: "#FBFBFB", // メインバックグラウンドで使用
    secondary: "#F7F7F7", // サブコンテンツのバックグラウンドで使用
    selected: "#F6F7F8", // リストアイテムなどが選択状態のときに使用
    auth: "#FBFBFB", // Authの背景色
    white: "#FFFFFF",
    brand: "#e8e8ec",
  },

  // タスクページ
  TaskRow: {
    default: {
      topTitle: "#FBFBFB",
      group: "#FBFBFB",
      project: "#FBFBFB",
      primaryItem: "#FAFAFA",
      addition: "#FFFFFF",
      none: "#FFFFFF",
      inProgress: "#FFFFFF",
      done: "#AEAEAE",
      closed: "#AEAEAE",
      expiredDeadline: "#fed1d2",
      weekDeadline: "#d3dcf2",
      nearDeadline: "#d2f0da",
      nowDeadline: "#fcefc9",
      hasNotification: "#fec73e",
      noNotification: "#dde1e5",
    },
    hover: {
      topTitle: "#EBEBEB",
      group: "#EBEBEB",
      project: "#EBEBEB",
      primaryItem: "#EAEAEA",
      addition: "#FFFFFF",
      none: "#EFEFEF",
      inProgress: "#EFEFEF",
      done: "#A7A7A7",
      closed: "#A7A7A7",
      expiredDeadline: "#FDC0CB",
      nearDeadline: "#FFF6B0",
      nowDeadline: "#FDC5B4",
    },
  },
  // 組織ページ
  StaffRow: {
    default: {
      none: "#FFFFFF",
    },
    hover: {
      none: "#EFEFEF",
    },
  },
  ProjectRow: {
    default: {
      none: "#FFFFFF",
    },
    hover: {
      none: "#EFEFEF",
    },
  },
  GroupRow: {
    default: {
      none: "#FFFFFF",
    },
    hover: {
      none: "#EFEFEF",
    },
  },
  // コミュニケーションページ
  MeetingRow: {
    default: {
      meetingType: "#FAFAFA",
      addition: "#FFFFFF",
      none: "#FFFFFF",
    },
    hover: {
      meetingType: "#EAEAEA",
      addition: "#FFFFFF",
      none: "#EFEFEF",
    },
  },
  // グラフ用
  Analytics: {
    blue: "#1E88E5",
    red: "#FF7474",
    green: "#00D4BB",
  },
  // その他。共通
  App: {
    primary: "#464BA8",
    accent: "#DE3E44",
    gray: "#BDBDBD",
    white: "#FFFFFF",
  },
  Accent: {
    primary: "#AD002D",
    secondary: "#181B39",
    orange: "#EA930A",
    lightOrange: "#FFC700",
    purple: "#624498",
    lightPurple: "#9C4AEF",
    blue: "#3391FF",
    green: "#4AC26B",
    yellow: "#F4BD25",
    red: "#FC484A",
    pink: "#EB466E",
  },
  Text: {
    primary: "#454746", // 主にメインテキストなどに使用
    secondary: "#727171", // 非アクティブ要素や注釈のテキストなどに使用
    white: "#FFFFFF",
    gray: "#8C949B",
    ghost: "#c6cacd",
    brand: "#464BA8",
    periodic: "#EF8D4A",
    purpleGray: "#858e9f",
    error: "#b20030",
  },
  Border: {
    primary: "#F6F7F8", // Divider/Border/Strokeが主にアクティブなときに使用
    secondary: "#F7F7F7", // Divider/Border/Strokeが主に非アクティブなときに使用
    gray: "#DADCE0",
    third: "#F1F1F1",
    headerBell: "#D3DBE2",
    purpleGray: "#dcb0ff",
    darkGray: "#A9A9A9",
    brand: "#464BA8",
    periodic: "#EF8D4A",
  },
  Background: {
    primary: "#FBFBFB", // メインバックグラウンドで使用
    secondary: "#F7F7F7", // サブコンテンツのバックグラウンドで使用
    selected: "#D0D0D0", // リストアイテムなどが選択状態のときに使用
    tertiary: "#F6F7F8", // セレクト状態などで使用月白(げっぱく)
    auth: "#FBFBFB", // Authの背景色
    white: "#FFFFFF",
    brand: "#464BA8",
    brandGray: "#D8DDE6",
    brandActive: "#6469C6",
    blur: "#000221",
    purpleGray: "#fcf9ff",
    pink: "#FFEBE9",
    dialogHeader: "#F6F8FA", // 通知設定ダイアログヘッダー等

    // アクセント
    blue: "#C6DBF2",
    green: "#CBE5D3",
    yellow: "#F0E4C3",
    red: "#F2CBCB",
    gray: "#DADBDD",
  },
  Form: {
    primary: "#FFFFFF", // 活性状態のときに使用
    secondary: "#FCFCFE", // 非活性状態のときに使用
  },
  Button: {
    primary: {
      color: "#2B2A27",
      text: "#FFFFFF",
    }, // メインボタン
    negative: {
      color: "#F4F3F8",
      text: "#B4B4BE",
    }, //主にボタンが非アクティブ状態で使用
    accent: {
      red: "#FF7663",
      gray: "#7F7F89",
      facebook: "#1877F2",
    },
    social: {
      facebook: "#5E6E9F",
    },
  },
  Admin: undefined,
} as const;
