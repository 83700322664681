import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { useAuth } from "@/custom-hooks/use-auth";
import { useCommonSetting } from "@/custom-hooks/use-common-setting";
import { DASHBOARD_PATH, LOGIN_PATH, ORG_STAFF_PATH, TASK_PATH } from "@/constants/app-path";
import { authTokenInfoAtom } from "@/global-states/common-atoms";
import { useAtom } from "jotai";

type Props = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = (props) => {
  const router = useRouter();
  const { authSync, fbAuthSync } = useAuth();
  const { authAdmin, initializing, setInitializing } = useCommonSetting();
  const [authTokenInfo] = useAtom(authTokenInfoAtom);

  useEffect(() => {
    (() => fbAuthSync(authTokenInfo?.fb_custom_token))();
  }, [authTokenInfo?.fb_custom_token]);

  useEffect(() => {
    (async () => {
      if (initializing) {
        const success = await authSync();
        let nextPage = LOGIN_PATH;
        if (success) {
          const pathname = router.pathname;
          if (pathname.startsWith(TASK_PATH)) {
            nextPage = "/task/all";
          } else if (pathname.startsWith(ORG_STAFF_PATH)) {
            nextPage = "/organization/staff/all";
          } else {
            nextPage = !pathname ? DASHBOARD_PATH : pathname;
          }
        }
        setTimeout(() => router.push(nextPage));
        setTimeout(() => setInitializing(false), 800);
      }
    })();
  }, [initializing]);

  useEffect(() => {
    if (!authAdmin) {
      return;
    }
    if (authAdmin) {
      const timeout = setTimeout(() => authSync(), 1000 * 60 * 3);
      return () => clearTimeout(timeout);
    }
  }, [authAdmin?.id]);

  return <>{props.children}</>;
};
