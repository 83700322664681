import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class AdminApi extends BaseApi {
  async getAdminList(
    params: APIPaths["/admin-dash/admin/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/admin/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/admin/list", { params })).data;
  }

  async postAdminNew(
    requestBody: APIPaths["/admin-dash/admin/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/admin/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/admin-dash/admin/new`, requestBody)).data;
  }

  async getAdminMe(): Promise<
    APIPaths["/admin-dash/admin/me"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get(`/admin-dash/admin/me`)).data;
  }

  async patchAdminMe(
    requestBody: APIPaths["/admin-dash/admin/me"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/admin/me"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/admin/me`, requestBody)).data;
  }

  async getAdminById(
    id: string,
  ): Promise<APIPaths["/admin-dash/admin/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/admin/${id}`)).data;
  }

  async putAdminMePassword(
    requestBody: APIPaths["/admin-dash/admin/me/password"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/admin/me/password"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/admin/me/password`, requestBody)).data;
  }

  async patchAdminById(
    id: string,
    requestBody: APIPaths["/admin-dash/admin/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/admin/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/admin/${id}`, requestBody)).data;
  }

  async deleteAdminById(
    id: string,
  ): Promise<APIPaths["/admin-dash/admin/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/admin/${id}`)).data;
  }

  async putAdminPasswordById(
    id: string,
    requestBody: APIPaths["/admin-dash/admin/{id}/password"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/admin/{id}/password"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/admin/${id}/password`, requestBody)).data;
  }
}
