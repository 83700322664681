import { APICompsSchema } from "@/apis/api-config";
import { CountryCode, CustomSelectOption, TaskKind } from "@/utils/common-types";

export const companyTypeOptions: { id: APICompsSchema["CompanyTypeEnum"]; name: string }[] = [
  {
    id: "corporation",
    name: "法人",
  },
  {
    id: "sole_proprietorship",
    name: "個人事業主",
  },
];

export const toCompanyTypeName = (_id: APICompsSchema["CompanyTypeEnum"]): string => {
  return companyTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toCompanyTypeOption = (
  id: APICompsSchema["CompanyTypeEnum"],
): CustomSelectOption<APICompsSchema["CompanyTypeEnum"]> => {
  return { id, name: toCompanyTypeName(id) };
};

export const initialCompanyTypeOption: CustomSelectOption<APICompsSchema["CompanyTypeEnum"]> =
  toCompanyTypeOption("corporation");

export const companyObjectToOption = (object: APICompsSchema["CompanyObject"]): CustomSelectOption<string> => {
  return { id: object.id, name: `${object.name}（${object.code}）` };
};

export const countryCodeOptions: { id: CountryCode; name: string }[] = [
  { id: "JP", name: "日本" },
  { id: "TW", name: "台湾" },
  { id: "KR", name: "韓国" },
  { id: "HK", name: "香港" },
  { id: "CN", name: "中国" },
];

export const toCountryCodeName = (_id: CountryCode): string => {
  return countryCodeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toCountryCodeOption = (id: CountryCode): CustomSelectOption<CountryCode> => {
  return { id, name: toCountryCodeName(id) };
};

export const initialCountryCodeOption: CustomSelectOption<CountryCode> = toCountryCodeOption("JP");

export const prefectureOptions: { id: APICompsSchema["PrefectureEnum"]; name: string }[] = [
  { id: "hokkaido", name: "北海道" },
  { id: "aomori", name: "青森県" },
  { id: "iwate", name: "岩手県" },
  { id: "miyagi", name: "宮城県" },
  { id: "akita", name: "秋田県" },
  { id: "yamagata", name: "山形県" },
  { id: "fukushima", name: "福島県" },
  { id: "ibaraki", name: "茨城県" },
  { id: "tochigi", name: "栃木県" },
  { id: "gunma", name: "群馬県" },
  { id: "saitama", name: "埼玉県" },
  { id: "chiba", name: "千葉県" },
  { id: "tokyo", name: "東京都" },
  { id: "kanagawa", name: "神奈川県" },
  { id: "niigata", name: "新潟県" },
  { id: "toyama", name: "富山県" },
  { id: "ishikawa", name: "石川県" },
  { id: "fukui", name: "福井県" },
  { id: "yamanashi", name: "山梨県" },
  { id: "nagano", name: "長野県" },
  { id: "gifu", name: "岐阜県" },
  { id: "shizuoka", name: "静岡県" },
  { id: "aichi", name: "愛知県" },
  { id: "mie", name: "三重県" },
  { id: "shiga", name: "滋賀県" },
  { id: "kyoto", name: "京都府" },
  { id: "osaka", name: "大阪府" },
  { id: "hyogo", name: "兵庫県" },
  { id: "nara", name: "奈良県" },
  { id: "wakayama", name: "和歌山県" },
  { id: "tottori", name: "鳥取県" },
  { id: "shimane", name: "島根県" },
  { id: "okayama", name: "岡山県" },
  { id: "hiroshima", name: "広島県" },
  { id: "yamaguchi", name: "山口県" },
  { id: "tokushima", name: "徳島県" },
  { id: "kagawa", name: "香川県" },
  { id: "ehime", name: "愛媛県" },
  { id: "kochi", name: "高知県" },
  { id: "fukuoka", name: "福岡県" },
  { id: "saga", name: "佐賀県" },
  { id: "nagasaki", name: "長崎県" },
  { id: "kumamoto", name: "熊本県" },
  { id: "oita", name: "大分県" },
  { id: "miyazaki", name: "宮崎県" },
  { id: "kagoshima", name: "鹿児島県" },
  { id: "okinawa", name: "沖縄県" },
];

export const toPrefectureName = (_id: APICompsSchema["PrefectureEnum"]): string => {
  return prefectureOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toPrefectureOption = (
  id: APICompsSchema["PrefectureEnum"],
): CustomSelectOption<APICompsSchema["PrefectureEnum"]> => {
  return { id, name: toPrefectureName(id) };
};

export const initialPrefectureOption: CustomSelectOption<APICompsSchema["PrefectureEnum"]> =
  toPrefectureOption("tokyo");

export const timezoneOptions: { id: APICompsSchema["TimezoneEnum"]; name: string }[] = [
  {
    id: "utc",
    name: "UTC",
  },
  {
    id: "asia_tokyo",
    name: "Asia/Tokyo",
  },
];

export const toTimezoneName = (_id: APICompsSchema["TimezoneEnum"]): string => {
  return timezoneOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toTimezoneOption = (
  id: APICompsSchema["TimezoneEnum"],
): CustomSelectOption<APICompsSchema["TimezoneEnum"]> => {
  return { id, name: toTimezoneName(id) };
};

export const initialTimezoneOption = toTimezoneOption("asia_tokyo");

export const workspaceTypeOptions: { id: APICompsSchema["WorkspaceTypeEnum"]; name: string }[] = [
  {
    id: "business",
    name: "ビジネス利用",
  },
  {
    id: "personal",
    name: "個人利用",
  },
];

export const toWorkspaceTypeName = (_id: APICompsSchema["WorkspaceTypeEnum"]): string => {
  return workspaceTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toWorkspaceTypeOption = (
  id: APICompsSchema["WorkspaceTypeEnum"],
): CustomSelectOption<APICompsSchema["WorkspaceTypeEnum"]> => {
  return { id, name: toWorkspaceTypeName(id) };
};

export const initialWorkspaceTypeOption = toWorkspaceTypeOption("personal");

export const workspaceStatusOptions: { id: APICompsSchema["WorkspaceStatusEnum"]; name: string }[] = [
  {
    id: "trial",
    name: "トライアル中",
  },
  {
    id: "active",
    name: "契約中",
  },
  {
    id: "archived",
    name: "アーカイブ中",
  },
  {
    id: "disabled",
    name: "利用停止中",
  },
];

export const toWorkspaceStatusName = (_id: APICompsSchema["WorkspaceStatusEnum"]): string => {
  return workspaceStatusOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toWorkspaceStatusOption = (
  id: APICompsSchema["WorkspaceStatusEnum"],
): CustomSelectOption<APICompsSchema["WorkspaceStatusEnum"]> => {
  return { id, name: toWorkspaceStatusName(id) };
};

export const initialWorkspaceStatusOption = toWorkspaceStatusOption("trial");

export const workspaceUserContractTypeOptions: {
  id: APICompsSchema["WorkspaceUserContractTypeEnum"];
  name: string;
}[] = [
  {
    id: "regular_employee",
    name: "正社員",
  },
  {
    id: "short_time_regular_employee",
    name: "短時間正社員",
  },
  {
    id: "contract_employee",
    name: "契約社員/嘱託社員",
  },
  {
    id: "part_time_worker",
    name: "パート",
  },
  {
    id: "part_time_job",
    name: "アルバイト",
  },
  {
    id: "temporary_worker",
    name: "派遣社員",
  },
  {
    id: "subcontracting",
    name: "業務委託",
  },
];

export const toWorkspaceUserContractTypeName = (_id: APICompsSchema["WorkspaceUserContractTypeEnum"]): string => {
  return workspaceUserContractTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toWorkspaceUserContractTypeOption = (
  id: APICompsSchema["WorkspaceUserContractTypeEnum"],
): CustomSelectOption<APICompsSchema["WorkspaceUserContractTypeEnum"]> => {
  return { id, name: toWorkspaceUserContractTypeName(id) };
};

export const workspaceUserGradeTypeOptions: { id: APICompsSchema["WorkspaceUserGradeTypeEnum"]; name: string }[] = [
  {
    id: "first",
    name: "1等級",
  },
  {
    id: "second",
    name: "2等級",
  },
  {
    id: "third",
    name: "3等級",
  },
  {
    id: "fourth",
    name: "4等級",
  },
  {
    id: "fifth",
    name: "5等級",
  },
  {
    id: "sixth",
    name: "6等級",
  },
  {
    id: "seventh",
    name: "7等級",
  },
  {
    id: "eighth",
    name: "8等級",
  },
  {
    id: "ninth",
    name: "9等級",
  },
  {
    id: "tenth",
    name: "10等級",
  },
];

export const toWorkspaceUserGradeTypeName = (_id: APICompsSchema["WorkspaceUserGradeTypeEnum"]): string => {
  return workspaceUserGradeTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toWorkspaceUserGradeTypeOption = (
  id: APICompsSchema["WorkspaceUserGradeTypeEnum"],
): CustomSelectOption<APICompsSchema["WorkspaceUserGradeTypeEnum"]> => {
  return { id, name: toWorkspaceUserGradeTypeName(id) };
};

export const workspaceUserRoleTypeOptions: {
  id: APICompsSchema["WorkspaceUserRoleTypeEnum"];
  name: string;
}[] = [
  {
    id: "general_user",
    name: "一般ユーザ",
  },
  {
    id: "workspace_manager",
    name: "ワークスペース管理者",
  },
  {
    id: "payment_manager",
    name: "支払い管理者",
  },
  {
    id: "owner",
    name: "オーナー",
  },
];

export const toWorkspaceUserRoleTypeName = (_id: APICompsSchema["WorkspaceUserRoleTypeEnum"]): string => {
  return workspaceUserRoleTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toWorkspaceUserRoleTypeOption = (
  id: APICompsSchema["WorkspaceUserRoleTypeEnum"],
): CustomSelectOption<APICompsSchema["WorkspaceUserRoleTypeEnum"]> => {
  return { id, name: toWorkspaceUserRoleTypeName(id) };
};

export const initialWorkspaceUserRoleTypeOption = toWorkspaceUserRoleTypeOption("general_user");

export const groupOfficialPositionTypeOptions: {
  id: APICompsSchema["GroupOfficialPositionTypeEnum"];
  name: string;
}[] = [
  { id: "general_manager", name: "本部長" },
  { id: "manager", name: "部長" },
  { id: "assistant_manager", name: "副部長" },
  { id: "section_head", name: "課長" },
  { id: "unit_head", name: "係長" },
  { id: "head", name: "主任" },
  { id: "general", name: "一般社員" },
];

export const toGroupOfficialPositionTypeName = (_id: APICompsSchema["GroupOfficialPositionTypeEnum"]): string => {
  return groupOfficialPositionTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toGroupOfficialPositionTypeOption = (
  id: APICompsSchema["GroupOfficialPositionTypeEnum"],
): CustomSelectOption<APICompsSchema["GroupOfficialPositionTypeEnum"]> => {
  return { id, name: toGroupOfficialPositionTypeName(id) };
};

export const groupTypeOptions: {
  id: APICompsSchema["GroupTypeEnum"];
  name: string;
}[] = [
  {
    id: "office",
    name: "室",
  },
  {
    id: "division",
    name: "本部",
  },
  {
    id: "department",
    name: "部",
  },
  {
    id: "section",
    name: "課",
  },
];

export const toGroupTypeName = (_id: APICompsSchema["GroupTypeEnum"]): string => {
  return groupTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toGroupTypeOption = (
  id: APICompsSchema["GroupTypeEnum"],
): CustomSelectOption<APICompsSchema["GroupTypeEnum"]> => {
  return { id, name: toGroupTypeName(id) };
};

export const initialGroupTypeOption = toGroupTypeOption("department");

export const meetingTypeOptions: {
  id: APICompsSchema["MeetingTypeEnum"];
  name: string;
}[] = [
  {
    id: "all",
    name: "全体会議",
  },
  {
    id: "management",
    name: "経営会議",
  },
  {
    id: "executive",
    name: "幹部会議",
  },
  {
    id: "group",
    name: "全体会議",
  },
  {
    id: "project",
    name: "プロジェクト会議",
  },
];

export const toMeetingTypeName = (_id: APICompsSchema["MeetingTypeEnum"]): string => {
  return meetingTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toMeetingTypeOption = (
  id: APICompsSchema["MeetingTypeEnum"],
): CustomSelectOption<APICompsSchema["MeetingTypeEnum"]> => {
  return { id, name: toMeetingTypeName(id) };
};

export const meetingObjectiveTypeOptions: {
  id: APICompsSchema["MeetingObjectiveTypeEnum"];
  name: string;
}[] = [
  {
    id: "decision_making",
    name: "意思決定",
  },
  {
    id: "monitoring",
    name: "意思決定",
  },
];

export const toMeetingObjectiveTypeName = (_id: APICompsSchema["MeetingObjectiveTypeEnum"]): string => {
  return meetingObjectiveTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toMeetingObjectiveTypeOption = (
  id: APICompsSchema["MeetingObjectiveTypeEnum"],
): CustomSelectOption<APICompsSchema["MeetingObjectiveTypeEnum"]> => {
  return { id, name: toMeetingObjectiveTypeName(id) };
};

export const adminRoleOptions: {
  id: APICompsSchema["AdminRoleEnum"];
  name: string;
}[] = [
  {
    id: "all_access",
    name: "全アクセス可",
  },
  {
    id: "tpl_access",
    name: "テンプレート管理のみ",
  },
  {
    id: "log_access",
    name: "各種履歴閲覧のみ",
  },
];

export const toAdminRoleName = (_id: APICompsSchema["AdminRoleEnum"]): string => {
  return adminRoleOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toAdminRoleOption = (
  id: APICompsSchema["AdminRoleEnum"],
): CustomSelectOption<APICompsSchema["AdminRoleEnum"]> => {
  return { id, name: toAdminRoleName(id) };
};

export const initialAdminRoleOption = toAdminRoleOption("all_access");

export const userStatusOptions: {
  id: APICompsSchema["UserStatusEnum"];
  name: string;
}[] = [
  {
    id: "waiting",
    name: "利用規約同意待ち",
  },
  {
    id: "active",
    name: "利用規約同意済",
  },
  {
    id: "disabled",
    name: "利用停止中",
  },
];

export const toUserStatusName = (_id: APICompsSchema["UserStatusEnum"]): string => {
  return userStatusOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toUserStatusOption = (
  id: APICompsSchema["UserStatusEnum"],
): CustomSelectOption<APICompsSchema["UserStatusEnum"]> => {
  return { id, name: toUserStatusName(id) };
};

export const periodicSettingTypeOptions: {
  id: APICompsSchema["PeriodicSettingTypeEnum"];
  name: string;
}[] = [
  {
    id: "daily",
    name: "日",
  },
  {
    id: "weekly",
    name: "週",
  },
  {
    id: "monthly",
    name: "ヶ月",
  },
  {
    id: "yearly",
    name: "年",
  },
];

export const toPeriodicSettingTypeName = (_id: APICompsSchema["PeriodicSettingTypeEnum"]): string => {
  return periodicSettingTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toPeriodicSettingTypeOption = (
  id: APICompsSchema["PeriodicSettingTypeEnum"],
): CustomSelectOption<APICompsSchema["PeriodicSettingTypeEnum"]> => {
  return { id, name: toPeriodicSettingTypeName(id) };
};

export const dayOfWeekTypeOptions: {
  id: APICompsSchema["DayOfWeekTypeEnum"];
  name: string;
}[] = [
  {
    id: "mon",
    name: "月",
  },
  {
    id: "tue",
    name: "火",
  },
  {
    id: "wed",
    name: "水",
  },
  {
    id: "thu",
    name: "木",
  },
  {
    id: "fri",
    name: "金",
  },
  {
    id: "sat",
    name: "土",
  },
  {
    id: "sun",
    name: "日",
  },
];

export const toDayOfWeekTypeName = (_id: APICompsSchema["DayOfWeekTypeEnum"]): string => {
  return dayOfWeekTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toDayOfWeekTypeOption = (
  id: APICompsSchema["DayOfWeekTypeEnum"],
): CustomSelectOption<APICompsSchema["DayOfWeekTypeEnum"]> => {
  return { id, name: toDayOfWeekTypeName(id) };
};

export const monthlyWeekTypeOptions: {
  id: APICompsSchema["MonthlyWeekTypeEnum"];
  name: string;
}[] = [
  {
    id: "first_week",
    name: "第1週",
  },
  {
    id: "second_week",
    name: "第2週",
  },
  {
    id: "third_week",
    name: "第3週",
  },
  {
    id: "fourth_week",
    name: "第4週",
  },
  {
    id: "final_week",
    name: "最終週",
  },
];

export const toMonthlyWeekTypeName = (_id: APICompsSchema["MonthlyWeekTypeEnum"]): string => {
  return monthlyWeekTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toMonthlyWeekTypeOption = (
  id: APICompsSchema["MonthlyWeekTypeEnum"],
): CustomSelectOption<APICompsSchema["MonthlyWeekTypeEnum"]> => {
  return { id, name: toMonthlyWeekTypeName(id) };
};

export const taskKindOptions: {
  id: TaskKind;
  name: string;
}[] = [
  {
    id: "nonperiodic",
    name: "非定型",
  },
  {
    id: "periodic",
    name: "定型",
  },
];

export const toTaskKindName = (_id: TaskKind): string => {
  return taskKindOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toTaskKindOption = (id: TaskKind): CustomSelectOption<TaskKind> => {
  return { id, name: toTaskKindName(id) };
};

export const primaryItemTypeOptions: {
  id: APICompsSchema["PrimaryItemTypeEnum"];
  name: string;
}[] = [
  {
    id: "management",
    name: "経営タスク",
  },
  {
    id: "project",
    name: "プロジェクトタスク",
  },
  {
    id: "group",
    name: "部署タスク",
  },
  {
    id: "private",
    name: "プライベートタスク",
  },
];

export const toPrimaryItemTypeName = (_id: APICompsSchema["PrimaryItemTypeEnum"]): string => {
  return primaryItemTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toPrimaryItemTypeOption = (
  id: APICompsSchema["PrimaryItemTypeEnum"],
): CustomSelectOption<APICompsSchema["PrimaryItemTypeEnum"]> => {
  return { id, name: toPrimaryItemTypeName(id) };
};

export const taskStatusOptions: {
  id: APICompsSchema["TaskStatusEnum"];
  name: string;
}[] = [
  {
    id: "in_progress",
    name: "進行中",
  },
  {
    id: "done",
    name: "完了",
  },
  {
    id: "closed",
    name: "終了",
  },
  {
    id: "archived",
    name: "アーカイブ済",
  },
];

export const toTaskStatusName = (_id: APICompsSchema["TaskStatusEnum"]): string => {
  return taskStatusOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toTaskStatusOption = (
  id: APICompsSchema["TaskStatusEnum"],
): CustomSelectOption<APICompsSchema["TaskStatusEnum"]> => {
  return { id, name: toTaskStatusName(id) };
};

export const taskPriorityTypeOptions: {
  id: APICompsSchema["TaskPriorityTypeEnum"];
  name: string;
}[] = [
  {
    id: "high",
    name: "A",
  },
  {
    id: "middle",
    name: "B",
  },
  {
    id: "low",
    name: "C",
  },
];

export const toTaskPriorityTypeName = (_id: APICompsSchema["TaskPriorityTypeEnum"]): string => {
  return taskPriorityTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toTaskPriorityTypeOption = (
  id: APICompsSchema["TaskPriorityTypeEnum"],
): CustomSelectOption<APICompsSchema["TaskPriorityTypeEnum"]> => {
  return { id, name: toTaskPriorityTypeName(id) };
};

export const taskSeverityTypeOptions: {
  id: APICompsSchema["TaskSeverityTypeEnum"];
  name: string;
}[] = [
  {
    id: "high",
    name: "A",
  },
  {
    id: "middle",
    name: "B",
  },
  {
    id: "low",
    name: "C",
  },
];

export const toTaskSeverityTypeName = (_id: APICompsSchema["TaskSeverityTypeEnum"]): string => {
  return taskSeverityTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toTaskSeverityTypeOption = (
  id: APICompsSchema["TaskSeverityTypeEnum"],
): CustomSelectOption<APICompsSchema["TaskSeverityTypeEnum"]> => {
  return { id, name: toTaskSeverityTypeName(id) };
};

export const taskPublicityTypeOptions: {
  id: APICompsSchema["TaskPublicityTypeEnum"];
  name: string;
}[] = [
  {
    id: "all",
    name: "全体",
  },
  {
    id: "internal",
    name: "非公開",
  },
];

export const toTaskPublicityTypeName = (_id: APICompsSchema["TaskPublicityTypeEnum"]): string => {
  return taskPublicityTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const taskTplPublicityTypeOptions: {
  id: APICompsSchema["TaskPublicityTypeEnum"];
  name: string;
}[] = [
  {
    id: "all",
    name: "表示",
  },
  {
    id: "internal",
    name: "非表示",
  },
];

export const toTaskTplPublicityTypeName = (_id: APICompsSchema["TaskPublicityTypeEnum"]): string => {
  return taskTplPublicityTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const toTaskPublicityTypeOption = (
  id: APICompsSchema["TaskPublicityTypeEnum"],
): CustomSelectOption<APICompsSchema["TaskPublicityTypeEnum"]> => {
  return { id, name: toTaskPublicityTypeName(id) };
};

export const PeriodicPatternTypeOptions: {
  id: APICompsSchema["PeriodicPatternTypeEnum"];
  name: string;
}[] = [
  {
    id: "daily_case1",
    name: "毎日",
  },
  {
    id: "daily_case2",
    name: "3日毎",
  },
  {
    id: "weekly_case1",
    name: "毎週金曜日",
  },
  {
    id: "weekly_case2",
    name: "毎週火曜日と木曜日",
  },
  {
    id: "monthly_case1",
    name: "毎月1日",
  },
  {
    id: "monthly_case2",
    name: "毎月末",
  },
  {
    id: "yearly_case1",
    name: "毎年年始1月1日",
  },
  {
    id: "yearly_case2",
    name: "毎年年末12月31日",
  },
];

export const toPeriodicPatternTypeName = (_id: APICompsSchema["PeriodicPatternTypeEnum"]): string => {
  return PeriodicPatternTypeOptions.find(({ id }) => id === _id)?.name || "-";
};

export const WorkspaceUserStatusOptions: {
  id: APICompsSchema["WorkspaceUserStatusEnum"];
  name: string;
}[] = [
  {
    id: "active",
    name: "利用中",
  },
  {
    id: "archived",
    name: "アーカイブ済",
  },
];

export const toWorkspaceUserStatusName = (_id: APICompsSchema["WorkspaceUserStatusEnum"]): string => {
  return WorkspaceUserStatusOptions.find(({ id }) => id === _id)?.name || "-";
};
