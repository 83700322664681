import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class CompanyApi extends BaseApi {
  async getCompanyList(
    params: APIPaths["/admin-dash/company/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/company/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/company/list", { params })).data;
  }

  async getCompanyIdNameListAll(
    params: APIPaths["/admin-dash/company/id-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/admin-dash/company/id-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/admin-dash/company/id-name/list-all", { params })).data;
  }

  async postCompanyNew(
    requestBody: APIPaths["/admin-dash/company/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/company/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post(`/admin-dash/company/new`, requestBody)).data;
  }

  async getCompanyById(
    id: string,
  ): Promise<APIPaths["/admin-dash/company/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/company/${id}`)).data;
  }

  async patchCompanyById(
    id: string,
    requestBody: APIPaths["/admin-dash/company/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/company/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/company/${id}`, requestBody)).data;
  }

  async putCompanyTaskTplRelation(
    id: string,
    requestBody: APIPaths["/admin-dash/company/{id}/task-tpl/relation"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/company/{id}/task-tpl/relation"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/company/${id}/task-tpl/relation`, requestBody)).data;
  }

  async deleteCompanyById(
    id: string,
  ): Promise<APIPaths["/admin-dash/company/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/company/${id}`)).data;
  }
}
