import { APICompsSchema } from "@/apis/api-config";

import { APP_ENV } from "@/constants/env";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const decodeBase64 = (data: string) => {
  return Buffer.from(data, "base64").toString("ascii");
};

const firebaseConfig = JSON.parse(decodeBase64(process.env.FIREBASE_CONFIG_JSON_BASE64 || "") || "{}");

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);

export const firestoreDB = getFirestore(firebaseApp);

export const getFirestoreColPath = (
  baseDirPath: APICompsSchema["FirestoreDirectoryPathTypeEnum"],
  workspaceId?: string,
): string => {
  let collectionPath = baseDirPath.replace("{env}", APP_ENV);
  if (workspaceId) {
    collectionPath = collectionPath.replace("{workspace_id}", workspaceId);
  }
  return collectionPath;
};
