import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class ProjectApi extends BaseApi {
  async getProjectList(
    params: APIPaths["/admin-dash/project/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/project/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/project/list", { params })).data;
  }

  async getProjectIdNameListAll(
    params: APIPaths["/admin-dash/project/id-name/list-all"]["get"]["parameters"]["query"],
  ): Promise<
    APIPaths["/admin-dash/project/id-name/list-all"]["get"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.get("/admin-dash/project/id-name/list-all", { params })).data;
  }

  async postProjectNew(
    requestBody: APIPaths["/admin-dash/project/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/project/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/project/new", requestBody)).data;
  }

  async getProjectById(
    id: string,
  ): Promise<APIPaths["/admin-dash/project/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/project/${id}`)).data;
  }

  async patchProjectById(
    id: string,
    requestBody: APIPaths["/admin-dash/project/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/project/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/project/${id}`, requestBody)).data;
  }

  async deleteProjectById(
    id: string,
  ): Promise<APIPaths["/admin-dash/project/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/project/${id}`)).data;
  }

  async putProjectPositionById(
    parameters: APIPaths["/admin-dash/project/{id}/position"]["put"]["parameters"]["path"],
    requestBody: APIPaths["/admin-dash/project/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/project/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.put(`/admin-dash/project/${parameters.id}/position`, requestBody)).data;
  }
}
