import { APIPaths } from "@/apis/api-config";
import BaseApi from "@/apis/base-api";

export class SecondaryItemApi extends BaseApi {
  async getSecondaryItemList(
    params: APIPaths["/admin-dash/secondary-item/list"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/secondary-item/list"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get("/admin-dash/secondary-item/list", { params })).data;
  }

  async postSecondaryItemNew(
    requestBody: APIPaths["/admin-dash/secondary-item/new"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/secondary-item/new"]["post"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.post("/admin-dash/secondary-item/new", requestBody)).data;
  }

  async getSecondaryItemById(
    id: string,
  ): Promise<APIPaths["/admin-dash/secondary-item/{id}"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.get(`/admin-dash/secondary-item/${id}`)).data;
  }

  async patchSecondaryItemById(
    id: string,
    requestBody: APIPaths["/admin-dash/secondary-item/{id}"]["patch"]["requestBody"]["content"]["application/json"],
  ): Promise<APIPaths["/admin-dash/secondary-item/{id}"]["patch"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.patch(`/admin-dash/secondary-item/${id}`, requestBody)).data;
  }

  async deleteSecondaryItemById(
    id: string,
  ): Promise<APIPaths["/admin-dash/secondary-item/{id}"]["delete"]["responses"]["200"]["content"]["application/json"]> {
    return (await this.delete(`/admin-dash/secondary-item/${id}`)).data;
  }

  async postSecondaryItemDuplicate(
    id: string,
    requestBody: APIPaths["/admin-dash/secondary-item/{id}/duplicate"]["post"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/secondary-item/{id}/duplicate"]["post"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.post(`/admin-dash/secondary-item/${id}/duplicate`, requestBody)).data;
  }

  async putSecondaryItemPosition(
    id: string,
    requestBody: APIPaths["/admin-dash/secondary-item/{id}/position"]["put"]["requestBody"]["content"]["application/json"],
  ): Promise<
    APIPaths["/admin-dash/secondary-item/{id}/position"]["put"]["responses"]["200"]["content"]["application/json"]
  > {
    return (await this.put(`/admin-dash/secondary-item/${id}/position`, requestBody)).data;
  }
}
