import { APICompsSchema } from "@/apis/api-config";

import { AppFontSizeType, CurrentCellState, HighlightActiveState } from "@/types/common-types";
import { atom } from "jotai";
import { CustomSelectOption, TaskKind } from "@/utils/common-types";
import { toTaskKindOption, toTaskStatusOption } from "@/apis/api-options";
import { UserCredential } from "@firebase/auth";

export const initializingAtom = atom<boolean>(true);
export const loadingAtom = atom<boolean>(false);
export const showSavingAtom = atom<boolean>(false);
export const dataGridLoadingAtom = atom<boolean>(false);
export const upOrDownKeyLongPressingAtom = atom<boolean>(false);

export const authTokenInfoAtom = atom<APICompsSchema["AuthTokenResponse"] | undefined>(undefined);
export const fbUserCredentialAtom = atom<UserCredential | undefined>(undefined);
export const searchTextAtom = atom<string>("");
export const highlightActiveStateAtom = atom<HighlightActiveState | undefined>(undefined);
export const appFontTypeAtom = atom<AppFontSizeType>("large");
export const imeInputCellStateAtom = atom<CurrentCellState | undefined>(undefined);

// テーブル左端のIndex + Reorderの列の幅のAtom
export const tableLeftIndexWidthAtom = atom<number>(46);

export const authAdminAtom = atom<APICompsSchema["FlatAdminObject"] | null>(null);

// current data
export const currentWorkspaceOptAtom = atom<CustomSelectOption<string> | null>(null);
export const currentGroupOptAtom = atom<CustomSelectOption<string> | null>(null);
export const currentProjectOptAtom = atom<CustomSelectOption<string> | null>(null);
export const currentPrimaryItemTypeAtom = atom<APICompsSchema["PrimaryItemTypeEnum"]>("management");
export const currentTaskStatusOptAtom = atom<CustomSelectOption<APICompsSchema["TaskStatusEnum"]>>(
  toTaskStatusOption("in_progress"),
);
export const currentTaskKindOptAtom = atom<CustomSelectOption<TaskKind>>(toTaskKindOption("nonperiodic"));

// options

export const workspaceOptsAtom = atom<CustomSelectOption<string>[]>([]);
export const groupOptsAtom = atom<CustomSelectOption<string>[]>([]);
export const projectOptsAtom = atom<CustomSelectOption<string>[]>([]);
