import { APIPaths } from "@/apis/api-config";

import BaseApi from "../base-api";

export class PlanContractApi extends BaseApi {
  async getPlanContractListAll(
    parameters: APIPaths["/admin-dash/plan-contract/list-all"]["get"]["parameters"]["query"],
  ): Promise<APIPaths["/admin-dash/plan-contract/list-all"]["get"]["responses"]["200"]["content"]["application/json"]> {
    return (
      await this.get("/admin-dash/plan-contract/list-all", {
        params: parameters,
      })
    ).data;
  }
}
