import { useCallback, useEffect, useState } from "react";

import { APICompsSchema } from "@/apis/api-config";
import { useCustomMediaQuery } from "@/custom-hooks/use-custom-media";
import {
  authAdminAtom,
  authTokenInfoAtom,
  currentGroupOptAtom,
  currentPrimaryItemTypeAtom,
  currentProjectOptAtom,
  currentTaskKindOptAtom,
  currentTaskStatusOptAtom,
  currentWorkspaceOptAtom,
  groupOptsAtom,
  initializingAtom,
  loadingAtom,
  projectOptsAtom,
  workspaceOptsAtom,
} from "@/global-states/common-atoms";
import { LocalStorageKeys } from "@/constants/local-storage-keys";

import axios, { AxiosError } from "axios";
import { useAtom } from "jotai";
import localforage from "localforage";
import { useSnackbar } from "notistack";
import { UseMutationOptions } from "react-query/types/react/types";

export const useCommonSetting = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { breakpoints } = useCustomMediaQuery();

  const [initializing, setInitializing] = useAtom(initializingAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const [authAdmin, setAuthAdmin] = useAtom(authAdminAtom);
  const [authTokenInfo, setAuthTokenInfo] = useAtom(authTokenInfoAtom);

  const [currentWorkspaceOpt, setCurrentWorkspaceOpt] = useAtom(currentWorkspaceOptAtom);
  const [currentGroupOpt, setCurrentGroupOpt] = useAtom(currentGroupOptAtom);
  const [currentProjectOpt, setCurrentProjectOpt] = useAtom(currentProjectOptAtom);
  const [currentPrimaryItemType, setCurrentPrimaryItemType] = useAtom(currentPrimaryItemTypeAtom);
  const [currentTaskStatusOpt, setCurrentTaskStatusOpt] = useAtom(currentTaskStatusOptAtom);
  const [currentTaskKindOpt, setCurrentTaskKindOpt] = useAtom(currentTaskKindOptAtom);

  const [workspaceOpts, setWorkspaceOpts] = useAtom(workspaceOptsAtom);
  const [groupOpts, setGroupOpts] = useAtom(groupOptsAtom);
  const [projectOpts, setProjectOpts] = useAtom(projectOptsAtom);

  const [fullHeight, setFullHeight] = useState<number>(200);
  const [fullWidth, setFullWidth] = useState<number>(200);
  const [drawerMinMaxWidth, setDrawerMinMaxWidth] = useState<number>(0);
  const [drawerLeftWidth, setDrawerLeftWidth] = useState<number>(0);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    setFullWidth(window.innerWidth);
    setFullHeight(window.innerHeight);
  }, [breakpoints]);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    if (breakpoints.up("lg")) {
      setDrawerMinMaxWidth(fullWidth * 0.3);
    } else if (breakpoints.up("md")) {
      setDrawerMinMaxWidth(fullWidth * 0.4);
    } else {
      setDrawerMinMaxWidth(fullWidth * 0.8);
    }
  }, [fullWidth]);

  useEffect(() => {
    setDrawerLeftWidth(fullWidth - drawerMinMaxWidth);
  }, [drawerMinMaxWidth]);

  const resetAuth = useCallback(async () => {
    setAuthAdmin(null);
    setAuthTokenInfo(undefined);
    setLoading(false);
    await localforage.removeItem(LocalStorageKeys.authAccessToken.key);
    await localforage.removeItem(LocalStorageKeys.authRefreshToken.key);
  }, []);

  const catchApiError = useCallback((err: any) => {
    if (axios.isAxiosError(err)) {
      const e = err as AxiosError<APICompsSchema["ErrorResponse"]>;
      console.error(e?.response?.data?.message || e?.response?.data?.dev_message);
      enqueueSnackbar(e?.response?.data?.message || e?.response?.data?.dev_message || "error", {
        variant: "error",
      });
    } else {
      console.error(err);
    }
  }, []);

  const checkAccessToken = useCallback(async () => {
    const authAccessToken = await localforage.getItem(LocalStorageKeys.authAccessToken.key);
    if (typeof authAccessToken === "string") {
      return authAccessToken;
    } else {
      console.error(authAccessToken);
      await resetAuth();
      return undefined;
    }
  }, []);

  const checkRefreshToken = useCallback(async () => {
    const authRefreshToken = await localforage.getItem(LocalStorageKeys.authRefreshToken.key);
    if (typeof authRefreshToken === "string") {
      return authRefreshToken;
    } else {
      console.error(authRefreshToken);
      await resetAuth();
      return undefined;
    }
  }, []);

  const appMutationOption: UseMutationOptions = {
    onMutate: () => setLoading(true),
    onError: (err) => catchApiError(err),
    onSettled: () => setLoading(false),
  };

  return {
    // val
    fullHeight,
    fullWidth,
    drawerMinMaxWidth,
    drawerLeftWidth,
    initializing,
    loading,
    authAdmin,
    authTokenInfo,
    workspaceOpts,
    groupOpts,
    projectOpts,
    currentWorkspaceOpt,
    currentGroupOpt,
    currentProjectOpt,
    currentPrimaryItemType,
    currentTaskStatusOpt,
    currentTaskKindOpt,
    // atom func
    setInitializing,
    setLoading,
    setAuthAdmin,
    setAuthTokenInfo,
    setWorkspaceOpts,
    setGroupOpts,
    setProjectOpts,
    setCurrentWorkspaceOpt,
    setCurrentGroupOpt,
    setCurrentProjectOpt,
    setCurrentPrimaryItemType,
    setCurrentTaskStatusOpt,
    setCurrentTaskKindOpt,
    // custom func
    resetAuth,
    catchApiError,
    checkAccessToken,
    checkRefreshToken,
    appMutationOption,
    enqueueSnackbar,
  };
};
